import React, { useCallback, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import TutorialCard from "./TutorialCard";

const DashboardTutorials = ({ tutorialData }) => {
  const [displayArrows, toggleDisplayArrows] = useState(false);
  const [ref, setRef] = useState(null);

  const onRefSet = useCallback((ref) => {
    setRef(ref);
    toggleDisplayArrows(ref?.scrollWidth > ref?.clientWidth);
  });

  const scroll = (scrollOffset) => {
    ref.scrollLeft += scrollOffset;
  };

  return (
    <Grid
      container
      sx={{
        marginBottom: 4,
        width: "100%",
      }}
    >
      <Grid item xs={12} sx={{ marginBottom: 4 }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 6,
          }}
        >
          <Grid item xs={6}>
            <Typography
              variant="h4"
              sx={{ fontSize: { xs: 16, sm: 16, md: 20 } }}
            >
              Tips on Getting Started
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: displayArrows ? "flex" : "none",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              sx={{
                marginRight: 2,
                border: "2px solid",
                borderColor: (theme) => theme.palette.primary["main"],
              }}
              onClick={() => scroll(-200)}
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              sx={{
                marginLeft: 2,
                border: "2px solid",
                borderColor: (theme) => theme.palette.primary["main"],
              }}
              onClick={() => scroll(200)}
            >
              <ChevronRightIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          justifyContent: "space-around",
          marginBottom: 4,
        }}
      >
        <Grid
          container
          wrap="nowrap"
          direction="row"
          ref={onRefSet}
          sx={{ overflow: "auto" }}
        >
          {tutorialData.map((x) => (
            <TutorialCard key={x.id} tutorialData={x} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default DashboardTutorials;
