import React, { useState } from "react";
import { Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import SnackBarSuccess from "../SnackBarSuccess";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

const PlaceOrderButton = ({
  orderText,
  placeOrder,
  iconButton,
  buttonText,
  openEmail,
}) => {
  const [markingOrderAsPlaced, toggleMarkingOrderAsPlaced] = useState();
  const [alertSuccess, setAlertSuccess] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyText = () => {
    navigator.clipboard.writeText(orderText);
    setAlertSuccess("Order copied to clipboard");
    handleClose();
  };

  const handlePlaceOrder = async () => {
    toggleMarkingOrderAsPlaced(true);
    navigator.clipboard.writeText(orderText);
    setAnchorEl(null);
    setAlertSuccess("Order has been copied and marked as placed!");
    await placeOrder();
    toggleMarkingOrderAsPlaced(false);
  };

  const handleOpenEmail = () => {
    openEmail(orderText);
    handleClose();
  };

  const handleOpenEmailAndMarkPlaced = async () => {
    toggleMarkingOrderAsPlaced(true);
    openEmail(orderText);
    setAnchorEl(null);
    setAlertSuccess(
      "Order email has been opened and order has been marked as placed!"
    );
    await placeOrder();
    toggleMarkingOrderAsPlaced(false);
  };

  return (
    <>
      {iconButton ? (
        <IconButton
          disabled={markingOrderAsPlaced}
          onClick={handleClick}
          sx={{
            border: "2px solid",
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <SendOutlinedIcon sx={{ fontSize: "14px" }} />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          sx={{
            marginRight: 2,
            borderColor: (theme) => theme.palette.primary[800],
          }}
          disabled={markingOrderAsPlaced}
          onClick={handleClick}
        >
          <Typography
            variant="largeButton"
            sx={{
              color: (theme) => theme.palette.pure.black,
              fontSize: { xs: 10, md: 16 },
              lineHeight: { xs: 1.2, md: 1.5 },
            }}
          >
            {buttonText ? buttonText : "Create Order"}
          </Typography>
        </Button>
      )}

      <Menu
        sx={{
          "& .MuiMenuItem-root.Mui-selected": {
            backgroundColor: (theme) => theme.palette.primary[800],
          },
          "& .MuiMenuItem-root:hover": {
            backgroundColor: (theme) => theme.palette.secondary[600],
          },
          "& .MuiMenuItem-root.Mui-selected:hover": {
            backgroundColor: (theme) => theme.palette.secondary[800],
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem sx={{ margin: "5px" }} onClick={handleOpenEmail}>
          <SendOutlinedIcon sx={{ marginRight: "5px" }} />
          Create Email
        </MenuItem>
        <MenuItem sx={{ margin: "5px" }} onClick={handleCopyText}>
          <ContentCopyOutlinedIcon sx={{ marginRight: "5px" }} />
          Copy Content
        </MenuItem>
        <MenuItem sx={{ margin: "5px" }} onClick={handlePlaceOrder}>
          <CheckOutlinedIcon sx={{ marginRight: "5px" }} />
          Copy and Mark as Placed
        </MenuItem>
        <MenuItem sx={{ margin: "5px" }} onClick={handleOpenEmailAndMarkPlaced}>
          <CheckOutlinedIcon sx={{ marginRight: "5px" }} />
          Create Email and Mark as Placed
        </MenuItem>
      </Menu>
      <SnackBarSuccess
        alertSuccess={alertSuccess}
        setAlertSuccess={setAlertSuccess}
      />
    </>
  );
};

export default PlaceOrderButton;
