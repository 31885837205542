import React, { useContext, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import PlaceOrderButton from "../../../components/PlaceOrderButton";
import inventoryObjects from "../../../clients/inventoryObjects";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import Store from "../../../Store/Store";
import utilFunctions from "../../../utilities/utilFunctions";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";

const VendorOrderCard = ({ order, placeOrder, vendorMap }) => {
  const [expanded, setExpanded] = useState(true);
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenEmail = (orderText) => {
    const email =
      vendorMap[order.vendorId]?.contacts[0]?.email || "no-email-found";
    const mailto_link =
      "mailto:" +
      email +
      "?subject=" +
      encodeURIComponent("New Order") +
      "&body=" +
      encodeURIComponent(orderText);
    document.location.href = mailto_link;
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          width: "100%",
          padding: 0,
          borderRadius: "8px",
          marginTop: 4,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain["300"],
        }}
      >
        <CardContent sx={{ width: "100%", height: "68px", padding: 0 }}>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "68px",
              marginBottom: 0,
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: 4,
                  }}
                >
                  <Box sx={{ marginRight: 4 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: { xs: "40px", md: "48px" },
                        height: { xs: "40px", md: "48px" },
                        border: "3px solid",
                        borderColor: (theme) => theme.palette.terrain[600],
                        borderRadius: "150px",
                        overflow: "hidden",
                      }}
                    >
                      <LocalShippingOutlinedIcon
                        sx={{ color: (theme) => theme.palette.terrain[600] }}
                      />
                    </Box>
                  </Box>
                  <Grid item sx={{ marginBottom: 2 }}>
                    <Box>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: { xs: 14, md: 20 },
                          lineHeight: { xs: 1.2, md: 1.2 },
                        }}
                      >
                        {order.vendor?.name}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: 2,
                  }}
                >
                  {order.orderStatus ===
                  inventoryObjects.OrderStatuses.preview ? (
                    <PlaceOrderButton
                      orderText={order.orderText}
                      placeOrder={() => placeOrder(order.id)}
                      buttonText="Send Order"
                      openEmail={handleOpenEmail}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CheckOutlinedIcon
                        sx={{
                          color: (theme) => theme.palette.success[800],
                          fontSize: "16px",
                          marginRight: 1,
                          paddingBottom: 1,
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 700,
                          color: (theme) => theme.palette.success[800],
                          textTransform: "none",
                        }}
                      >
                        Placed
                      </Typography>
                    </Box>
                  )}

                  {!expanded ? (
                    <IconButton
                      sx={{
                        color: (theme) => theme.palette.pure.white,
                        height: "32px",
                        width: "32px",
                      }}
                      onClick={handleExpandClick}
                    >
                      <KeyboardArrowDownOutlinedIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      sx={{
                        height: "36px",
                        width: "32px",
                      }}
                      onClick={handleExpandClick}
                    >
                      <ExpandLessOutlinedIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent
            sx={{
              backgroundColor: (theme) => theme.palette.terrain[50],
              borderTop: "1px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              minHeight: "66px",
            }}
          >
            <Grid item sx={{ marginBottom: 2 }}>
              {order.lineItems?.map((x, i) => (
                <>
                  {i > 0 && (
                    <Typography
                      variant="body2"
                      sx={{ color: (theme) => theme.palette.primary[800] }}
                    >
                      -
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 400, whiteSpace: "pre-line" }}
                  >
                    {x.productName}{" "}
                    {x.unitSize === null
                      ? ""
                      : `(${x.unitSize} ${x.unitSizeAbbreviation}`}
                    {x.sku === null ? ")" : `SKU: ${x.sku}`}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 700 }}>
                    {x.orderAmount} {x.purchaseUnitName}{" "}
                    {`(${x.totalUnitsOrdered} units)`}
                  </Typography>
                </>
              ))}
            </Grid>
          </CardContent>
        </Collapse>
        <CardContent
          sx={{
            backgroundColor: (theme) => theme.palette.terrain[50],
            borderTop: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
            minHeight: "66px",
          }}
        >
          <Grid
            item
            sx={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.terrain[500],
                marginRight: 1,
              }}
            >
              Products:
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.pure.black,
                marginRight: 1,
              }}
            >
              {order.lineItems?.length}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.terrain[500],
                marginRight: 1,
              }}
            >
              {"|"}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.terrain[500],
                marginRight: 1,
              }}
            >
              Total Cost:
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.pure.black,
                marginRight: 1,
              }}
            >
              {order.estimatedCost !== null
                ? `${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
                    order.estimatedCost.amount,
                    locale,
                    currency
                  )}`
                : "N/A"}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default VendorOrderCard;
