import React, { useContext, useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
//import OrderStatusChip from "../../../components/OrderStatusChip";
import OrderStatusCTA from "../../../components/OrderStatusCTA";
import { useNavigate, useParams } from "react-router-dom";
import Store from "../../../Store/Store";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import utilFunctions from "../../../utilities/utilFunctions";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteOrderModal from "../../../components/Modals/DeleteOrderModal";

var moment = require("moment");

const tableCellStyle = {
  height: "48px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderBottom: "none",
  borderTop: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
};

const OrderPreviewRow = ({
  order,
  refetchExistingOrders,
  vendorMap,
  setAlertSuccess,
}) => {
  const { establishmentid: establishmentId } = useParams();
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [deleteOrderModalOpen, setDeleteOrderModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const useMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDeleteOrderModal = () => {
    setDeleteOrderModalOpen(!deleteOrderModalOpen);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const deleteOrderMutation = useMutation(inventoryClient.deleteOrder, {
    onSuccess: () => {
      refetchExistingOrders();
    },
  });

  const handleDeleteOrder = async () => {
    try {
      const archivePayload = {
        establishmentId: store.currentEstablishment?.id,
        orderId: order.id,
      };

      deleteOrderMutation.mutate(archivePayload);
    } catch (err) {
      console.log(err);
    }
  };

  const markOrderAsPlacedMutation = useMutation(
    inventoryClient.markOrderAsPlaced,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["order-details", { establishmentId, orderId: order.id }],
        });

        queryClient.invalidateQueries({
          queryKey: ["ordercart", { establishmentId }],
        });

        refetchExistingOrders();
      },
    }
  );

  const handlePlaceOrder = async () => {
    await markOrderAsPlacedMutation.mutateAsync({
      establishmentId: establishmentId,
      orderId: order.id,
    });
  };

  const handleOpenEmail = async (orderText) => {
    const email =
      vendorMap[order.vendorId]?.contacts[0]?.email || "no-email-found";
    const mailto_link =
      "mailto:" +
      email +
      "?subject=" +
      encodeURIComponent("New Order") +
      "&body=" +
      encodeURIComponent(orderText);
    document.location.href = mailto_link;
  };

  return (
    <>
      <TableRow sx={{ height: 24, width: "100%" }}>
        <TableCell
          sx={[
            tableCellStyle,
            {
              maxWidth: "120px",
              minWidth: useMobile ? "100px" : "10px",
              cursor: "pointer",
            },
          ]}
          onClick={() => navigate(`/spec/${establishmentId}/order/${order.id}`)}
        >
          <Typography nowrap variant="body2">
            {moment(order.createdDate).format("MM-DD-YYYY")}
          </Typography>
        </TableCell>
        <TableCell
          sx={[
            tableCellStyle,
            {
              width: "220px",
              cursor: "pointer",
            },
          ]}
          onClick={() => navigate(`/spec/${establishmentId}/order/${order.id}`)}
        >
          <Typography noWrap variant="body1">
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                minWidth: "220px",
                maxWidth: "220px",
                textTransform: "none",
              }}
            >
              {order.vendor?.name}
            </div>
          </Typography>
        </TableCell>
        <TableCell
          sx={[tableCellStyle, { width: "120px", cursor: "pointer" }]}
          onClick={() => navigate(`/spec/${establishmentId}/order/${order.id}`)}
        >
          <Typography variant="body1">
            {order.estimatedCost !== null
              ? `${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
                  order.estimatedCost.amount,
                  locale,
                  currency
                )}`
              : "N/A"}
          </Typography>
        </TableCell>
        <TableCell
          sx={[tableCellStyle, { width: "120px", cursor: "pointer" }]}
          onClick={() => navigate(`/spec/${establishmentId}/order/${order.id}`)}
        >
          <Typography variant="body1">
            {moment(order.createdDate).format("hh:mm a")}
          </Typography>
        </TableCell>
        <TableCell sx={[tableCellStyle, { width: "120px" }]}>
          <Box>
            <OrderStatusCTA
              status={order.orderStatus}
              orderText={order.orderText}
              placeOrder={handlePlaceOrder}
              openEmail={handleOpenEmail}
              setAlertSuccess={setAlertSuccess}
            />
          </Box>
        </TableCell>
        {/* <TableCell sx={[tableCellStyle, { width: "84px" }]}>
        <OrderStatusChip status={order.orderStatus} />
      </TableCell> */}
        <TableCell sx={[tableCellStyle, { width: "48px" }]}>
          <IconButton sx={{ margin: 0, padding: 0 }}>
            <MoreHorizOutlinedIcon
              sx={{ color: (theme) => theme.palette.primary[800] }}
              onClick={handleOptionClick}
            />
          </IconButton>
        </TableCell>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={toggleDeleteOrderModal} sx={{ margin: "5px" }}>
            <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
            Delete
          </MenuItem>
        </Menu>
      </TableRow>
      <DeleteOrderModal
        deleteCallback={handleDeleteOrder}
        isOpen={deleteOrderModalOpen}
        toggleModalOpen={toggleDeleteOrderModal}
      />
    </>
  );
};

export default OrderPreviewRow;
