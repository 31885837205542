import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Store from "../../../Store/Store";

const FooterGridWrapper = ({ children }) => (
  <Grid
    container
    direction={"row"}
    sx={{
      borderTop: (theme) => `1px solid ${theme.palette.terrain[400]}`,
      backgroundColor: (theme) => theme.palette.terrain[50],
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "80px",
      transition: (theme) =>
        theme.transitions.create(["width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      display: "flex",
      alignItems: "center",
      padding: "0 24px", // Padding for consistent spacing
      boxSizing: "border-box", // Ensure padding doesn't affect width
    }}
  >
    {children}
  </Grid>
);

const StockCountFooter = ({ submit, buttonText }) => {
  const [loading, toggleLoading] = useState(false);

  const { sideNavOpen } = useContext(Store);

  useEffect(() => {
    toggleLoading(false);
  });

  return (
    <FooterGridWrapper open={sideNavOpen}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          maxWidth: "2440px",
          paddingRight: 4,
        }}
      >
        <Grid item>
          <Box sx={{ padding: 2 }}>
            <Button
              type="button"
              variant="contained"
              sx={{
                width: "148px",
                height: "42px",
                backgroundColor: (theme) =>
                  buttonText === "Complete"
                    ? theme.palette.success[600]
                    : theme.palette.primary[800],
              }}
              onClick={submit}
            >
              <Typography variant="largeButton">
                {loading ? <CircularProgress color="inherit" /> : buttonText}
              </Typography>{" "}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </FooterGridWrapper>
  );
};

export default StockCountFooter;
