import React, { useContext } from "react";
import { Button, Typography, Grid, styled } from "@mui/material";
import Store from "../../../../Store/Store";

const FooterGridWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: 3,
  borderTop: `1px solid ${theme.palette.terrain[400]}`,
  backgroundColor: theme.palette.terrain[50],
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "80px",
  transition: theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  display: "flex",
  alignItems: "center",
  padding: "0 24px", // Padding for consistent spacing
  boxSizing: "border-box", // Ensure padding doesn't affect width
}));

const AddMenuItemsFooter = ({ submit, handleBack, itemCount }) => {
  const { sideNavOpen } = useContext(Store);

  return (
    <FooterGridWrapper open={sideNavOpen}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "2440px",
        }}
      >
        <Grid item xs={sideNavOpen ? 2 : 0} />
        <Grid item xs={sideNavOpen ? 4 : 6}>
          <Button
            variant="outlined"
            sx={{ height: "42px", width: "120px" }}
            onClick={handleBack}
          >
            <Typography variant="smallButton">Back</Typography>
          </Button>
        </Grid>
        <Grid item xs={6} sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}>
            <Button
              type="button"
              variant="contained"
              sx={{ width: "180px", height: "42px" }}
              onClick={submit}
              disabled={itemCount === 0}
            >
              <Typography variant="largeButton">Complete</Typography>
            </Button>
        </Grid>
      </Grid>
    </FooterGridWrapper>
  );
};

export default AddMenuItemsFooter;
