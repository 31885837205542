const inventoryObjects = {
  stockCountTypes: {
    inventoryAudit: 1,
    partial: 2,
  },
  StockCountStatuses: {
    Unknown: 0,
    NotStarted: 1,
    InProgress: 2,
    Complete: 3,
    Cancelled: 4,
  },
  OrderStatuses: {
    unknown: 0,
    preview: 1,
    draft: 2,
    ordered: 3,
    received: 4,
    partiallyReceived: 5,
    backOrdered: 6,
    cancelled: 7,
  },
  invoiceStatuses: {
    unknown: 0,
    unreconciled: 1,
    reconciled: 2,
    approved: 3,
  },
  kegTransferTypes: {
    credit: 0,
    expense: 1,
  },
  miscLineItemTypes: {
    credit: 0,
    expense: 1,
  },
  changeSetTypes: {
    0: "Created",
    1: "Updated",
    2: "Deleted",
    3: "Completed",
  },
  areaCountTypes: {
    default: 0,
    unorganized: 1,
  },
  parStatusTypes: {
    unknown: 0,
    good: 1,
    under: 2,
    over: 3,
  },
  productSortProperties: {
    name: 0,
    createdDate: 1,
  },
  rateLimitType: {
    recipes: 0,
    menus: 1,
  },
};

const inventoryCountTypesMap = {
  1: "Full Audit",
  2: "Partial Audit",
};

const productSizeTypes = {
  Volumetric: 1,
  Mass: 2,
  Arbitrary: 3,
};

const unitOfMeasurementTypes = {
  Volumetric: 0,
  Mass: 1,
  Arbitrary: 2,
};

const unitSizeToUomSizeTypeMap = {
  1: 0,
  2: 1,
  3: 2,
};

const menuItemTypes = {
  Recipe: 0,
  ProductFormat: 1,
};

const recipeIngredientTypes = {
  ProductFormat: 0,
  Recipe: 1,
  OpenText: 2,
};

const basicInventoryReportTypes = {
  product: 0,
  productFormat: 1,
  vendors: 2,
  families: 3,
  categories: 4,
  subcategories: 5,
  expenseGroups: 6,
  expenseSubgroups: 7,
  areas: 8,
  productPerArea: 9,
  familyPerArea: 10,
};

const orderReportTypes = {
  topProduct: 0,
  topProductFormat: 1,
  topFamily: 2,
  topCategory: 3,
  topSubcategory: 4,
  topVendor: 5,
  topExpenseGroup: 6,
  topExpenseSubgroup: 7,
};

const invoiceReportTypes = {
    topSpendingProduct: 0,
    topSpendingProductFormat: 1,
    topSpendingFamilies: 2,
    topSpendingCategories: 3,
    topSpendingSubcategories: 4,
    topSpendingVendors: 5,
    topSpendingExpenseGroups: 6,
    topSpendingExpenseSubgroups: 7,
    itemizedInvoiceByDate: 8,
    invoiceTotalsByVendor: 9,
    invoiceProductPriceChangesOverTime: 10,
    invoiceNonItemizedExpenseGroupSummary: 11,
    invoiceExpenseGroupsVendorsOverTime: 12,
    invoiceExpenseSubgroupsVendorsOverTime: 13,
    invoiceExpenseGroupTotals: 14,
    invoiceExpenseSubgroupsPer: 15,
}

const orderReportOrderByTypes = {
  quantityOrdered: 0,
  orderTotal: 1,
};

const basicInventoryReportOrderByOptions = {
  valueOnHands: 0,
  unitAmount: 1,
};

const reportDateGroupings = {
  day: 0,
  week: 1,
  month: 2,
  quarter: 3,
  year: 4
}

const invoiceNonItemizedOrderByTypesForSummary = {
  dateNewestFirst: 0,
  dateOldestFirst: 1,
  vendorAtoZ: 2,
  vendorZtoA: 3,
  invoiceTotalLowToHigh: 4,
  invoiceTotalHighToLow: 5,
}

const invoiceTotalsByVendorSortOption = {
  vendorsAtoZ: 0,
  vendorsZotA: 1
}

const expenseSubgroupsPerInvoiceSortOptions = {
  dateNewestFirst: 0,
  dateOldestFirst: 1
}

const invoiceExpenseGroupingReportGroupingOptions = {
  both: 0,
  expenseGroupsOnly: 1,
  expenseSubgroupsOnly: 2
}

export default inventoryObjects;

export {
  inventoryCountTypesMap,
  productSizeTypes,
  unitOfMeasurementTypes,
  menuItemTypes,
  recipeIngredientTypes,
  unitSizeToUomSizeTypeMap,
  basicInventoryReportTypes,
  basicInventoryReportOrderByOptions,
  orderReportTypes,
  orderReportOrderByTypes,
  reportDateGroupings,
  invoiceReportTypes,
  invoiceNonItemizedOrderByTypesForSummary,
  invoiceTotalsByVendorSortOption,
  invoiceExpenseGroupingReportGroupingOptions,
  expenseSubgroupsPerInvoiceSortOptions
};
