import { Button, Grid, Typography, styled } from "@mui/material";
import React, { useContext } from "react";
import Store from "../../../../Store/Store";

const FooterGridWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.terrain[400]}`,
  backgroundColor: theme.palette.terrain[50],
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "80px",
  transition: theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  display: "flex",
  alignItems: "center",
  padding: "0 24px", // Padding for consistent spacing
  boxSizing: "border-box", // Ensure padding doesn't affect width
  zIndex: 10,
}));

const AddProductFooter = ({ activeStep, newProducts, handleSubmit }) => {
  const { sideNavOpen } = useContext(Store);

  if (activeStep > 4) {
    return null;
  }

  return (
    <FooterGridWrapper open={sideNavOpen}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          maxWidth: "2440px",
        }}
      >
        <Grid
          item
          sx={{
            paddingLeft: 8,
            marginRight: 4,
          }}
        >
          <Button
            type="button"
            variant="contained"
            disabled={!newProducts || newProducts.length === 0}
            sx={{ width: "180px", height: "42px" }}
            onClick={handleSubmit}
          >
            <Typography variant="smallButton">Submit</Typography>
          </Button>
        </Grid>
      </Grid>
    </FooterGridWrapper>
  );
};

export default AddProductFooter;
