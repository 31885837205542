import React, { useContext, useState } from "react";
import { Box, Typography, Grid, Alert, AlertTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import AddProductCard from "./AddProductCard";
import { cloneDeep } from "lodash";
import AddProductFooter from "./AddProductFooter";
import AddProductHeader from "./AddProductHeader";
import inventoryClient from "../../../../clients/inventoryClient";
import Store from "../../../../Store/Store";
import SpecSpinner from "../../../../components/SpecSpinner";
import AddProductCTA from "./AddProductCTA";

const AddProductForm = ({
  productFamilies,
  addProductFamily,
  addCategory,
  addSubcategory,
  productFamilyMap,
  categoryMap,
  subcategoryMap,
  handleAddProductComplete,
}) => {
  const [loading, toggleLoading] = useState(false);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [isGenericError, toggleGenericError] = useState(false);
  const [missingFamiliesOrNameError, toggleMissingFamiliesOrName] =
    useState(false);

  const store = useContext(Store);

  const {
    register,
    setValue,
    reset,
    control,
    formState: { isValid },
  } = useForm({ mode: "all" });

  const addNewProduct = (product) => {
    const newState = [product, ...store.newProductsToAdd];
    store.setNewProductsToAdd(newState);
    reset();
    toggleLoading(false);
  };

  const removeProduct = (product) => {
    const newState = store.newProductsToAdd.filter((x) => x.id !== product.id);
    store.setNewProductsToAdd(newState);
  };

  const updateProduct = (productId, propToUpdate, newPropValue) => {
    const newState = store.newProductsToAdd.map((x) => {
      if (x.id === productId) {
        const toUpdate = cloneDeep(x);
        toUpdate[propToUpdate] = newPropValue;
        return toUpdate;
      }

      return x;
    });
    store.setNewProductsToAdd(newState);
  };

  const handleUpdateFamily = (productId, newFamilyId) => {
    const newState = store.newProductsToAdd.map((x) => {
      if (x.id === productId) {
        if (x.productFamilyId === newFamilyId) {
          return x;
        }

        const toUpdate = cloneDeep(x);
        toUpdate.subcategoryId = null;
        toUpdate.categoryId = null;
        toUpdate.productFamilyId = newFamilyId;
        return toUpdate;
      }

      return x;
    });
    store.setNewProductsToAdd(newState);
  };

  const handleUpdateCategory = (productId, newCategoryId) => {
    const newState = store.newProductsToAdd.map((x) => {
      if (x.id === productId) {
        if (x.categoryId === newCategoryId) {
          return x;
        }
        const toUpdate = cloneDeep(x);
        toUpdate.subcategoryId = null;
        toUpdate.categoryId = newCategoryId;
        return toUpdate;
      }

      return x;
    });
    store.setNewProductsToAdd(newState);
  };

  const handleClearFamily = (productId) => {
    const newState = store.newProductsToAdd.map((x) => {
      if (x.id === productId) {
        const toUpdate = cloneDeep(x);
        toUpdate.subcategoryId = null;
        toUpdate.categoryId = null;
        toUpdate.productFamilyId = null;
        return toUpdate;
      }

      return x;
    });
    store.setNewProductsToAdd(newState);
  };

  const handleClearCategory = (productId) => {
    const newState = store.newProductsToAdd.map((x) => {
      if (x.id === productId) {
        const toUpdate = cloneDeep(x);
        toUpdate.subcategoryId = null;
        toUpdate.categoryId = null;
        return toUpdate;
      }

      return x;
    });
    store.setNewProductsToAdd(newState);
  };

  const handleFormCompletion = async () => {
    toggleLoading(true);
    setSpinnerOpen(true);
    toggleMissingFamiliesOrName(false);
    toggleGenericError(false);
    const response = await inventoryClient.addProducts(
      store.newProductsToAdd,
      store.currentEstablishment?.id
    );

    if (response.error) {
      toggleLoading(false);
      setSpinnerOpen(false);

      if (response.status === 400) {
        toggleMissingFamiliesOrName(true);
      } else {
        toggleGenericError(true);
      }
    } else {
      store.setNewProductsToAdd([]);
      toggleMissingFamiliesOrName(false);
      toggleLoading(false);
      setSpinnerOpen(false);
      handleAddProductComplete();
    }
  };

  if (loading) {
    return <SpecSpinner open={spinnerOpen} text="Products being added..." />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        direction="row"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={12} md={3} sx={{ marginTop: 4 }}>
          <Typography variant="h2">Add Products</Typography>
        </Grid>
      </Grid>

      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom: 30,
          }}
        >
          <AddProductHeader addNewProduct={addNewProduct} />
          {missingFamiliesOrNameError && (
            <Typography
              variant="h4"
              sx={{ color: (theme) => theme.palette.error[600] }}
            >
              All products must have their name and families filled in before
              they can be added.
            </Typography>
          )}
          {isGenericError && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              An error ocurred while trying to save your products.
            </Alert>
          )}
          {store.newProductsToAdd.length === 0 && <AddProductCTA />}
          {store.newProductsToAdd && store.newProductsToAdd.length > 0 && (
            <Grid container spacing={2}>
              {store.newProductsToAdd.map((value) => (
                <AddProductCard
                  product={value}
                  register={register}
                  control={control}
                  setValue={setValue}
                  removeProduct={removeProduct}
                  productName={value.name}
                  key={value.id}
                  updateProduct={updateProduct}
                  productFamilies={productFamilies}
                  createNewFamily={addProductFamily}
                  createNewCategory={addCategory}
                  createNewSubcategory={addSubcategory}
                  clearFamily={handleClearFamily}
                  clearCategory={handleClearCategory}
                  productFamilyMap={productFamilyMap}
                  selectFamily={handleUpdateFamily}
                  selectCategory={handleUpdateCategory}
                  categoryMap={categoryMap}
                  subcategoryMap={subcategoryMap}
                  missingFamiliesOrNameError={missingFamiliesOrNameError}
                />
              ))}
            </Grid>
          )}
        </Box>
      </>
      <AddProductFooter
        newProducts={store.newProductsToAdd}
        loading={loading}
        isValid={isValid}
        handleSubmit={handleFormCompletion}
      />
    </Box>
  );
};

export default AddProductForm;
