import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import VendorFilters from "./VendorFilters";
import {
  invoiceReportTypes,
  expenseSubgroupsPerInvoiceSortOptions,
} from "../../../../../clients/inventoryObjects";
import { presetDateRangeOptions } from "../../../../../utilities/dateRangeUtils";
import DateRange from "./DateRange";
import { useMutation } from "react-query";
import inventoryClient from "../../../../../clients/inventoryClient";
import utilFunctions from "../../../../../utilities/utilFunctions";
import ReportingFooter from "../../ReportingFooter";
import InsufficientReportDataModal from "../../../../../components/Modals/InsufficientReportDataModal";
import SortBy from "./SortBy";

const dateSelectionFormats = {
  presetOption: 0,
  customRange: 1,
};

const ExpenseSubgroupsPerInvoice = ({
  establishmentTimezone,
  vendors,
  setAlertSuccess,
  setErrorMessage,
  establishmentId,
}) => {
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customStartDateDisplay, setCustomStartDateDisplay] = useState(null);
  const [customEndDateDisplay, setCustomEndDateDisplay] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [selectedPresetDateRange, setSelectedPresetDateRange] = useState(
    presetDateRangeOptions[0].value
  );
  const [dateSelectionFormat, setDateSelectionFormat] = useState(
    dateSelectionFormats.presetOption
  );
  const [isUsingVendorFilter, toggleIsUsingVendorFilter] = useState(false);
  const [vendorFilters, setVendorFilters] = useState([]);
  const [sortChoice, setSortChoice] = useState(
    expenseSubgroupsPerInvoiceSortOptions.dateNewestFirst
  );
  const [isEmptyResultModalOpen, toggleIsEmptyResultModalOpen] =
    useState(false);

  const generateInvoiceReportMutation = useMutation(
    inventoryClient.generateInvoiceReport,
    {
      onSuccess: (response) => {
        if (response?.status === 204) {
          toggleIsEmptyResultModalOpen(true);
        } else {
          utilFunctions.downloadFile(
            response?.data?.report?.invoiceReportMetadata?.s3PresignedUrl
          );
          setAlertSuccess(
            "Report generated successfully, your download should have started."
          );
        }
      },
      onError: () => {
        setErrorMessage("Report generation failed!");
      },
    }
  );

  const handleGenerateReport = () => {
    const payload = {
      establishmentId: establishmentId,
      reportType: invoiceReportTypes.invoiceExpenseSubgroupsPer,
      invoiceExpenseSubgroupsPerFilters: {
        vendorIdFilters: isUsingVendorFilter
          ? vendorFilters.map((x) => x.id)
          : [], // api treats an empty array as not wanting filters
        orderBy: sortChoice,
      },
    };

    if (dateSelectionFormat == dateSelectionFormats.presetOption) {
      const dateRange = presetDateRangeOptions
        .find((x) => x.value === selectedPresetDateRange)
        .func(establishmentTimezone);
      payload.startDate = dateRange.start;
      payload.endDate = dateRange.end;
    } else {
      payload.startDate = customStartDate;
      payload.endDate = customEndDate;
    }

    generateInvoiceReportMutation.mutate(payload);
  };

  return (
    <>
      <Box>
        <Box sx={{ marginTop: 8 }}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "fit-content",
                width: "70%",
                borderRadius: 2,
                backgroundColor: (theme) => theme.palette.terrain[200],
                border: "1px solid",
                borderColor: (theme) => theme.palette.terrain[300],
                padding: 2,
              }}
            >
              <Typography variant="body2">
                Line items grouped by expense subgroup and invoice.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: 8 }}>
            <DateRange
              customStartDate={customStartDate}
              setCustomStartDate={setCustomStartDate}
              customEndDate={customEndDate}
              setCustomEndDate={setCustomEndDate}
              selectedPresetDateRange={selectedPresetDateRange}
              setSelectedPresetDateRange={setSelectedPresetDateRange}
              dateSelectionFormat={dateSelectionFormat}
              setDateSelectionFormat={setDateSelectionFormat}
              dateSelectionFormats={dateSelectionFormats}
              presetDateRangeOptions={presetDateRangeOptions}
              establishmentTimezone={establishmentTimezone}
              customStartDateDisplay={customStartDateDisplay}
              setCustomStartDateDisplay={setCustomStartDateDisplay}
              customEndDateDisplay={customEndDateDisplay}
              setCustomEndDateDisplay={setCustomEndDateDisplay}
            />
          </Box>
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <VendorFilters
            isUsingVendorFilter={isUsingVendorFilter}
            toggleIsUsingVendorFilter={toggleIsUsingVendorFilter}
            vendors={vendors}
            vendorFilters={vendorFilters}
            setVendorFilters={setVendorFilters}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <SortBy
            sortType={sortChoice}
            setSortChoice={setSortChoice}
          />
        </Box>
      </Box>
      <ReportingFooter
        buttonText={"Create Report"}
        disableButton={
          isUsingVendorFilter && vendorFilters?.length <= 0 // if we haven't chosen any expense groups but checked the box
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange && // if we selected custom range but didn't choose dates
              (!customEndDate || !customStartDate)
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange &&
              customEndDate < customStartDate // if the custom end date is before the start date
            ? true
            : false
        }
        submit={handleGenerateReport}
        isLoading={generateInvoiceReportMutation?.isLoading}
      />
      <InsufficientReportDataModal
        isOpen={isEmptyResultModalOpen}
        toggleModalOpen={toggleIsEmptyResultModalOpen}
        loading={false}
      />
    </>
  );
};

export default ExpenseSubgroupsPerInvoice;
