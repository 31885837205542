import React, { useContext } from "react";
import { Box, Button, Typography, Grid, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Store from "../../../Store/Store";

const FooterGridWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  borderTop: `1px solid ${theme.palette.terrain[400]}`,
  backgroundColor: theme.palette.terrain[50],
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "80px",
  transition: theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  display: "flex",
  alignItems: "center",
  padding: "0 24px", // Padding for consistent spacing
  boxSizing: "border-box", // Ensure padding doesn't affect width
}));

const ReportingFooter = ({ submit, buttonText, isLoading, disableButton }) => {
  const { sideNavOpen } = useContext(Store);

  return (
    <FooterGridWrapper open={sideNavOpen}>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        sx={{ maxWidth: "2440px", margin: "0 auto" }} // Center the content
      >
        <Box>
          <Button
            type="button"
            variant="contained"
            sx={{
              width: "148px",
              height: "42px",
              backgroundColor: (theme) =>
                buttonText === "Complete"
                  ? theme.palette.success[600]
                  : theme.palette.primary[800],
            }}
            onClick={submit}
            disabled={isLoading || disableButton}
          >
            <Typography variant="largeButton">
              {isLoading ? <CircularProgress color="inherit" size={24} /> : buttonText}
            </Typography>
          </Button>
        </Box>
      </Grid>
    </FooterGridWrapper>
  );
};

export default ReportingFooter;
