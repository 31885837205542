import {
  Box,
  Grid,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import utilFunctions from "../../../../utilities/utilFunctions";
import moment from "moment-timezone";
import GetAppIcon from "@mui/icons-material/GetApp";

const DateRange = ({
  customStartDate,
  setCustomStartDate,
  customEndDate,
  setCustomEndDate,
  selectedPresetDateRange,
  presetDateRangeOptions,
  setSelectedPresetDateRange,
  dateSelectionFormat,
  setDateSelectionFormat,
  dateSelectionFormats,
  establishmentTimezone,
}) => {
  const { control } = useForm();

  const handleFormatChange = (format) => {
    setDateSelectionFormat(format);
  };

  const handlePresetChange = (e) => {
    setSelectedPresetDateRange(e.target.value);
  };

  const handleStartDateSelect = (e) => {
    const selectedDate = moment(e);
    const zonedDate = selectedDate.tz(establishmentTimezone, true);
    setCustomStartDate(zonedDate.startOf("day").toDate());
  };

  const handleEndDateSelect = (e) => {
    const selectedDate = moment(e);
    const zonedDate = selectedDate.tz(establishmentTimezone, true);
    setCustomEndDate(zonedDate.endOf("day").toDate());
  };

  const selectedStyle = {
    cursor: "pointer",
    width: "100%",
    height: "90px",
    borderRadius: "4px",
    border: "1px solid",
    borderColor: (theme) => theme.palette.primary[800],
    backgroundColor: (theme) => theme.palette.terrain[200],
    marginBottom: 2,
  };

  const defaultStyle = {
    cursor: "pointer",
    width: "100%",
    height: "90px",
    border: "1px solid gray",
    borderRadius: "4px",
    marginBottom: 2,
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Typography variant="h5">Date Range</Typography>
      </Box>
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{ marginTop: 2, width: { xs: "100%", md: "70%" } }}
      >
        <Grid item xs={6}>
          <Box
            onClick={() =>
              handleFormatChange(dateSelectionFormats.presetOption)
            }
            sx={
              dateSelectionFormat === dateSelectionFormats.presetOption
                ? selectedStyle
                : defaultStyle
            }
          >
            <Grid container sx={{ display: "flex", alignItems: "center" }}>
              <Grid item>
                <Radio
                  checked={
                    dateSelectionFormat === dateSelectionFormats.presetOption
                  }
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: 12, sm: 12, md: 16 },
                    color:
                      dateSelectionFormat === dateSelectionFormats.presetOption
                        ? (theme) => theme.palette.primary[800]
                        : (theme) => theme.palette.pure.black,
                  }}
                >
                  Set Time Frame
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ paddingLeft: 2, paddingRight: 2, marginTop: -1 }}>
              <Select
                size="small"
                label={null}
                value={selectedPresetDateRange}
                onChange={handlePresetChange}
                sx={{
                  width: "100%",
                  height: "40px",
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              >
                {presetDateRangeOptions.map((x) => (
                  <MenuItem value={x.value} key={x.value}>
                    {x.key}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            onClick={() => handleFormatChange(dateSelectionFormats.customRange)}
            sx={
              dateSelectionFormat === dateSelectionFormats.customRange
                ? selectedStyle
                : defaultStyle
            }
          >
            <Grid container sx={{ display: "flex", alignItems: "center" }}>
              <Grid item>
                <Radio
                  checked={
                    dateSelectionFormat === dateSelectionFormats.customRange
                  }
                />
              </Grid>
              <Grid item direction="row">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: 12, sm: 12, md: 16 },
                    color:
                      dateSelectionFormat === dateSelectionFormats.customRange
                        ? (theme) => theme.palette.primary[800]
                        : (theme) => theme.palette.pure.black,
                  }}
                >
                  Custom Range
                </Typography>
                {dateSelectionFormat === dateSelectionFormats.customRange &&
                customStartDate &&
                customEndDate &&
                customEndDate < customStartDate ? (
                  <Typography
                    sx={{
                      marginBottom: 1,
                      color: (theme) => theme.palette.error.main,
                      fontWeight: 700,
                    }}
                  >
                    End date must be after Start date
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <Box
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Controller
                valueName="selected"
                control={control}
                name="startDate"
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      dateFormat="mm/dd/yyyy"
                      placeholder="mm/dd/yyyy"
                      onChange={(e) => {
                        field.onChange(e);
                        handleStartDateSelect(e);
                      }}
                      value={customStartDate}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          autoComplete="off"
                          onFocus={utilFunctions.highlightInputOnFocus}
                          fullWidth
                          {...params}
                          sx={{
                            height: "32px !important",
                            "& legend": { display: "none" },
                            width: "49%",
                          }}
                          value={customStartDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                valueName="selected"
                control={control}
                name="endDate"
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      dateFormat="mm/dd/yyyy"
                      placeholder="mm/dd/yyyy"
                      onChange={(e) => {
                        field.onChange(e);
                        handleEndDateSelect(e);
                      }}
                      value={customEndDate}
                      slots={{ openPickerIcon: GetAppIcon }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          autoComplete="off"
                          onFocus={utilFunctions.highlightInputOnFocus}
                          fullWidth
                          {...params}
                          sx={{
                            "& legend": { display: "none" },
                            width: "49%",
                          }}
                          value={customEndDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DateRange;
