import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import RecentlyUpdatedTable from "./components/RecentlyUpdatedTable";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import FullSummary from "./components/FullSummary";
import FreemiumDashboard from "./components/FreemiumDashboard";
import RecentlyUpdatedIndex from "./components/RecentlyUpdatedIndex";
import useReportingOverview from "../../hooks/useReportingOverview";
import useStaffMembers from "../../hooks/useStaffMembers";
import { useParams } from "react-router-dom";
import SpecSpinner from "../../components/SpecSpinner";
import useEstablishment from "../../hooks/useEstablishment";
import Store from "../../Store/Store";
import { startCase } from "lodash";
import useRecentUpdates from "../../hooks/useRecentUpdates";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";
import useMyMemberships from "../../hooks/useMyMemberships";
import useUserProfile from "../../hooks/useUserProfile";
import MobileDisplayAlertModal from "../../components/Modals/MobileDisplayAlertModal";
import DashboardTutorials from "./components/DashboardTutorials";
import HowToCreateMenus from "../../assets/img/thumbnails/HowToCreateMenus.webp";
import HowToUseProductLibrary from "../../assets/img/thumbnails/HowToUseProductLibrary.webp";
import HowToUseRecipeLibrary from "../../assets/img/thumbnails/HowToUseRecipeLibrary.webp";
import AddingStaffMembers from "../../assets/img/thumbnails/AddingStaffMembers.webp";
import HowToPlaceAnOrder from "../../assets/img/thumbnails/HowToPlaceAnOrder.webp";
import HowToCostRecipe30Seconds from "../../assets/img/thumbnails/HowToCostRecipe30Seconds.webp";
import HowToCostFood from "../../assets/img/thumbnails/HowToCostFood.jpg";
import HowToCostDrink from "../../assets/img/thumbnails/HowToCostDrink.jpg";

const tutorialData = [
  {
    description: "How to Cost a Recipe in 30 seconds",
    embedId: "wC_onZiwPVM?si=a0SKK683croBoWLW",
    link: "https://youtu.be/wC_onZiwPVM",
    thumbnail: HowToCostRecipe30Seconds,
  },
  {
    description: "How to Use the Product Library in Spec",
    embedId: "xZqULQfa41A?si=H8p1CsZiU_KPIVFv",
    link: "https://youtu.be/xZqULQfa41A",
    thumbnail: HowToUseProductLibrary,
  },
  {
    description: "How to Use the Recipe Library in Spec",
    embedId: "ChfNKWVW8CE?si=Z2EVQRPInSLk6jmV",
    link: "https://youtu.be/ChfNKWVW8CE",
    thumbnail: HowToUseRecipeLibrary,
  },
  {
    description: "How to Create a Menu using Spec",
    embedId: "jGNDu_g_TGc?si=TbZH9oJR06V3ax57",
    link: "https://youtu.be/jGNDu_g_TGc?feature=shared",
    thumbnail: HowToCreateMenus,
  },
  {
    description: "Adding & Managing Staff members",
    embedId: "DMwpXQcMgno?si=OK1wUgH3q4Q7hxwP",
    link: "https://youtu.be/DMwpXQcMgno",
    thumbnail: AddingStaffMembers,
  },
  {
    description: "How to Place an Order",
    embedId: "1Yuu4klDrfk?si=mYneXYwAEhFQe0tX",
    link: "https://youtu.be/1Yuu4klDrfk",
    thumbnail: HowToPlaceAnOrder,
  },
  {
    description: "How to Cost a Drink with Spec",
    embedId: "UESvbxQaR1Q?si=c-dy7fnu4farNc96",
    link: "https://youtu.be/UESvbxQaR1Q",
    thumbnail: HowToCostDrink,
  },
  {
    description: "How to Cost Food using Spec",
    embedId: "embed/c8CpYgnhdpM?si=Sx8JXsOsENg6HrjF",
    link: "https://youtu.be/c8CpYgnhdpM",
    thumbnail: HowToCostFood,
  },
];

const Dashboard = () => {
  const [recentlyUpdatedAll, toggleRecentlyUpdatedAll] = useState(false);
  const [isMobileAlertModalOpen, toggleMobileAlertModalOpen] = useState(false);

  const { establishmentid: establishmentId } = useParams();
  const store = useContext(Store);
  const { data: staffMemberData, isLoading: isLoadingStaffMembers } =
    useStaffMembers(establishmentId);
  const { data: reportingOverviewData, isLoading: isLoadingReportingOverview } =
    useReportingOverview(establishmentId);
  const { data: establishmentData, isLoading: isLoadingEstablishment } =
    useEstablishment(establishmentId, store);
  const { data: profileData, isLoading: isLoadingProfile } = useUserProfile();
  const { data: recentUpdatesData, isLoading: isLoadingRecentUpdates } =
    useRecentUpdates(establishmentId);
  const { data: subscriptionState, isLoading: isLoadingSubscriptionState } =
    useEstablishmentSubscription(establishmentId);
  const { data: memberships, isLoading: isLoadingMemberships } =
    useMyMemberships();

  useEffect(() => {
    const isMobile =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    const mobileWarningShown = localStorage.getItem("mobileWarningShown");

    if (isMobile && !mobileWarningShown) {
      localStorage.setItem("mobileWarningShown", true);
      toggleMobileAlertModalOpen(true);
    }
  }, []);

  if (
    isLoadingReportingOverview ||
    isLoadingStaffMembers ||
    isLoadingEstablishment ||
    isLoadingProfile ||
    isLoadingRecentUpdates ||
    isLoadingSubscriptionState ||
    isLoadingMemberships
  ) {
    return <SpecSpinner text="Loading dashboard..." open />;
  }

  // const toggleMobileAlertModalOpen = () => {
  //   setIs(false);
  // };

  return (
    <>
      <MobileDisplayAlertModal
        isOpen={isMobileAlertModalOpen}
        toggleModalOpen={toggleMobileAlertModalOpen}
      />
      {recentlyUpdatedAll === true ? (
        <RecentlyUpdatedIndex
          toggleRecentlyUpdatedAll={toggleRecentlyUpdatedAll}
          staffData={staffMemberData?.membershipProfiles || []}
        />
      ) : (
        <Box
          sx={{
            maxWidth: "98%",
            minWidth: "98%",
            padding: 4,
            marginTop: 6,
          }}
        >
          <Box>
            <Typography variant="h2">
              {" "}
              Hi {startCase(profileData.profile?.firstName || "")}!
            </Typography>
          </Box>
          {/* New User View vs Paid User View */}
          {subscriptionState?.isActive ? (
            <FullSummary
              inventoryReportingOverview={reportingOverviewData}
              staffMemberCount={staffMemberData?.membershipProfiles?.length}
              establishment={establishmentData}
            />
          ) : (
            <FreemiumDashboard
              establishment={establishmentData}
              inventoryReportingOverview={reportingOverviewData}
              staffMemberCount={staffMemberData?.membershipProfiles?.length}
              memberships={memberships}
            />
          )}
          {/* DASHBOARD TUTORIALS */}
          <DashboardTutorials tutorialData={tutorialData} />

          {/* Recent Updated Table */}
          {recentUpdatesData?.recentChanges?.length > 0 && (
            <Box sx={{ marginTop: 6 }}>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <Typography variant="h4">Recently Updated</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => toggleRecentlyUpdatedAll(true)}
                  >
                    <Typography
                      variant="largeButton"
                      sx={{ color: (theme) => theme.palette.primary[800] }}
                    >
                      See All
                    </Typography>
                    <ArrowForwardIosOutlinedIcon
                      sx={{
                        fontSize: 20,
                        color: (theme) => theme.palette.primary[800],
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ marginTop: 2 }}>
                <RecentlyUpdatedTable
                  recentUpdates={recentUpdatesData.recentChanges || []}
                  staffData={staffMemberData?.membershipProfiles || []}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Dashboard;
