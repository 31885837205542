import React from "react";
import { Box, Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const TutorialCard = ({ tutorialData }) => {
  return (
    <Box sx={{ width: "240px", marginRight: 2, cursor: "pointer" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "Center",
          position: "relative",
          width: "240px !important",
          minWidth: "240px !important",
          height: "160px",
          borderRadius: "8px",
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[300],
          backgroundColor: "#000",
          "&:hover": {
            backgroundColor: (theme) => theme.palette.terrain[100],
          },
        }}
        onClick={() => window.open(tutorialData.link, "_blank")}
      >
        {" "}
        <Box
          sx={{
            width: "40px",
            height: "40px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0,0,0,.50)",
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PlayCircleOutlineIcon
            sx={{ fontSize: 24, opacity: "100% !important", color: "#fff" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            height: "80%",
            // backgroundImage: `url(${tutorialData.thumbnail})`,
            backgroundImage: `url(${tutorialData.thumbnail})`,
          }}
        />
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="subtitle1">{tutorialData.description}</Typography>
      </Box>
    </Box>
  );
};

export default TutorialCard;
