import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { expenseSubgroupsPerInvoiceSortOptions } from "../../../../../clients/inventoryObjects";

const SortBy = ({ sortType, setSortChoice }) => {
  const toggleButtonStyle = {
    borderTopRightRadius: "8px !important",
    borderBottomRightRadius: "8px !important",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important",
    border: "none",
    backgroundColor: (theme) => theme.palette.terrain[200],
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: (theme) => theme.palette.terrain[900],
    },
  };

  const handleSortChoice = (_, v) => {
    setSortChoice(v);
  };
  return (
    <Box>
      <Box>
        <Typography variant="h5">Sort By</Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <ToggleButtonGroup value={sortType} onChange={handleSortChoice} exclusive>
          <ToggleButton
            value={expenseSubgroupsPerInvoiceSortOptions.dateNewestFirst}
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Newest First
            </Typography>
          </ToggleButton>
          <ToggleButton
            value={expenseSubgroupsPerInvoiceSortOptions.dateOldestFirst}
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Oldest First
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default SortBy;
