import React from "react";
import configuration from "../configuration.json";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Store from "../Store/Store";
import Login from "../views/Login";
import Profile from "../views/Profile";
import Establishments from "../views/Establishments";
import Homebase from "../views/Homebase";
import { session } from "../utilities/session";
import { identityConfig } from "../clients/identityClient";
import StaffManagement from "../views/StaffManagement";
import SessionExpiredModal from "./Modals/SessionExpiredModal";
import Register from "../views/Register";
import AdminMessages from "../views/AdminMessages";
import { ThemeProvider } from "@mui/material/styles";
import { configureTheme } from "../Theme/theme";
import Layout from "./Layout";
import EstablishmentDetails from "../views/EstablishmentDetails";
import MenuDetails from "../views/MenuDetails";
import { CssBaseline } from "@mui/material";
import utilFunctions from "../utilities/utilFunctions";
import MenuItemDetails from "../views/MenuItemDetails";
import SpecClassicCocktails from "../views/SpecClassicCocktails";
import ProductLibrary from "../views/ProductLibrary";
import Vendors from "../views/Vendors";
import StockCount from "../views/StockCount";
import AreasDisplay from "../views/Areas";
import AreaDetails from "../views/AreaDetails";
import { QueryClient, QueryClientProvider } from "react-query";
import StockCountDetails from "../views/StockCountDetails";
import Ordering from "../views/Ordering";
import Invoices from "../views/Invoices";
import NewOrder from "../views/NewOrder";
import OrderReview from "../views/OrderReview";
import VendorOrders from "../views/Ordering/components/VendorOrders";
import OrderDetails from "../views/OrderDetails";
import ReviewInvoice from "../views/Invoices/components/ReviewInvoice";
import Reporting from "../views/Reporting";
import InvoiceDetails from "../views/InvoiceDetails";
import Menus from "../views/Menus/index.js";
import MenuDetailsNEW from "../views/MenuDetailsNEW";
import Recipes from "../views/Recipes";
import RecipeDetails from "../views/RecipeDetails";
import Settings from "../views/Settings";
import Dashboard from "../views/Dashboard";
import StockCountReview from "../views/StockCountReview";
import ErrorBoundary from "./ErrorBoundaries/DefaultError";
import MasterSubmissionsControl from "../views/MasterSubmissionsControl";
import OrganizationDisplay from "../views/Organization";
import Families from "../views/Organization/FamiliesTab";
import LocationChangeMonitor from "./LocationChangeMonitor.js";
import ExpenseGroups from "../views/Organization/ExpenseGroupTab";
import LogRocket from "logrocket";
import inventoryObjects from "../clients/inventoryObjects.js";
import ProfileDetails from "../views/ProfileDetails/index.js";
import AccountClosedConfirmationModal from "./Modals/ConfirmationModal/index.js";
import { GoogleOAuthProvider } from '@react-oauth/google';

const { SplitFactory, SplitSdk } = require("@splitsoftware/splitio-react");

LogRocket.init(configuration.logRocketKey);

const queryClient = new QueryClient();
class App extends React.Component {
  state = {
    tokenExpired: false,
    authenticated: false,
    user: undefined,
    userId: undefined,
    showSessionExpired: false,
    establishments: [],
    currentEstablishment: undefined,
    selectedCountry: "",
    activeRoutes: {
      menu: undefined,
      vendors: undefined,
      organization: undefined,
      productLibrary: undefined,
      invoices: undefined,
      stockCount: undefined,
      recipes: undefined,
      ordering: undefined,
    },
    sideNavOpen: true,
    theme: configureTheme(),
    themePreference: "light",
    invalidateQueriesQueue: [],
    recipeFilters: {
      sortAsc: true,
      sortByCreatedDate: false,
      tagFilters: [],
      showInactive: false,
    },
    recipeSelectedPage: 1,
    recipeSearchText: "",
    productLibraryPage: 1,
    productLibrarySortAscending: true,
    productLibraryPropertyForSort: inventoryObjects.productSortProperties.name,
    productLibrarySortFilters: {},
    productLibrarySearchText: "",
    selectedProductLibraryPanel: 0,
    newProductsToAdd: [],
    showAccountClosedModal: false,
  };

  constructor(props) {
    super(props);

    const token = localStorage.getItem("at");

    if (token) {
      let tokenData = {};
      try {
        tokenData = utilFunctions.parseJwt(token);

        if (!tokenData || Date.now() >= tokenData.exp * 1000) {
          localStorage.removeItem("at");
        } else {
          identityConfig.accessToken = token;
          this.state.authenticated = true;
          this.state.loadProfile = true;
          this.state.userId = tokenData.sub;
        }
      } catch (err) {
        console.log(err);
        localStorage.removeItem("at");
      }
    }
  }

  toggleSideNavOpen = () => {
    this.setState({
      sideNavOpen: !this.state.sideNavOpen,
    });
  };

  setTokenExpired = () => {
    localStorage.removeItem("at");
    this.resetCachedEstablishmentData();
    this.setState({
      tokenExpired: true,
      authenticated: false,
      user: undefined,
      userId: undefined,
      showSessionExpired: true,
    });
  };

  handleLogInSuccess = async (user, userId, skipReload) => {
    LogRocket.identify(userId, {
      name: `${user?.firstName} ${user?.lastName}`,
    });
    this.setState({
      authenticated: true,
      tokenExpired: false,
      user: user,
      selectedCountry: user ? user.country : "us",
      userId: userId,
      theme: configureTheme({
        useDarkMode: user?.webThemePreference === "dark" ? true : false,
      }),
    });
    // hacky way to refresh the split io sdk with the userid
    if (!skipReload) {
      window.location.href = `${window.location.origin}/establishments`;
    }
  };

  updateUserProfile = (profile) => {
    const webThemePreference = JSON.parse(
      profile?.metaData
    )?.webThemePreference;

    if (webThemePreference !== this.state.themePreference) {
      this.setState({
        user: profile,
        themePreference: webThemePreference,
        theme: configureTheme({
          useDarkMode: webThemePreference === "dark" ? true : false,
        }),
      });
    } else {
      this.setState({
        user: profile,
      });
    }
  };

  updateEstablishmentOptions = (establishments) => {
    this.setState({
      establishments,
    });
  };

  updateCurrentEstablishment = (establishment) => {
    LogRocket.identify(this.state.userId, {
      establishmentId: establishment?.id,
    });
    this.setState({
      currentEstablishment: establishment,
    });
  };

  updateSelectedCountry = (country) => {
    this.setState({
      selectedCountry: country,
    });
  };

  updateActiveRoute = (key, newActiveRoute) => {
    const activeRouteCopy = Object.assign({}, this.state.activeRoutes);
    activeRouteCopy[key] = newActiveRoute;
    this.setState({
      activeRoutes: activeRouteCopy,
    });
  };

  handleLogout = (showAccountClosedModal) => {
    localStorage.removeItem("at");
    try {
      window.FB.logout();
    } catch (ex) {
      console.log(ex);
    }
    this.resetCachedEstablishmentData();
    this.setState({
      authenticated: false,
      tokenExpired: false,
      user: undefined,
      userId: undefined,
      showAccountClosedModal: showAccountClosedModal === true ? true : false,
    });
  };

  resetActiveRoutes = () => {
    this.setState({
      activeRoutes: {
        menu: undefined,
        vendors: undefined,
        organization: undefined,
        productLibrary: undefined,
        invoices: undefined,
        stockCount: undefined,
        recipes: undefined,
        ordering: undefined,
      },
    });
  };

  setRecipeFilters = (newFilters) => {
    this.setState({
      recipeFilters: newFilters,
    });
  };

  setRecipeSelectedPage = (page) => {
    this.setState({
      recipeSelectedPage: page,
    });
  };

  setProductLibraryPage = (page) => {
    this.setState({
      productLibraryPage: page,
    });
  };

  setRecipeSearchText = (content) => {
    this.setState({
      recipeSearchText: content,
    });
  };

  setProductLibrarySearchText = (content) => {
    this.setState({
      productLibrarySearchText: content,
    });
  };

  setProductLibrarySortFilters = (filters) => {
    this.setState({
      productLibrarySortFilters: filters,
    });
  };

  setProductLibraryPropertyForSort = (sortProperty) => {
    this.setState({
      productLibraryPropertyForSort: sortProperty,
    });
  };

  toggleProductLibrarySortAscending = (flag) => {
    this.setState({
      productLibrarySortAscending: flag,
    });
  };

  setSelectedProductLibraryPanel = (panelIndex) => {
    this.setState({
      selectedProductLibraryPanel: panelIndex,
    });
  };

  setNewProductsToAdd = (updatedProductsToAdd) => {
    this.setState({
      newProductsToAdd: updatedProductsToAdd,
    });
  };

  closeAccountClosedModal = () => {
    this.setState({
      showAccountClosedModal: false,
    });
  };

  resetCachedEstablishmentData = () => {
    this.setState({
      recipeFilters: {
        sortAsc: true,
        sortByCreatedDate: false,
        tagFilters: [],
        showInactive: false,
      },
      recipeSelectedPage: 1,
      recipeSearchText: "",
      productLibraryPage: 1,
      productLibrarySortAscending: true,
      productLibraryPropertyForSort:
        inventoryObjects.productSortProperties.name,
      productLibrarySortFilters: {},
      productLibrarySearchText: "",
      newProductsToAdd: [],
    });
  };

  // currently this should only be used for root keys to make comparisons easy
  // So for a key like this ["test-key", { id: someId }], the appending should only
  // pass "test-key" - this will match any query thats cached at a more granular level
  appendInvalidateQueriesQueue = (queryKey) => {
    if (this.state.invalidateQueriesQueue?.some((x) => x === queryKey)) {
      return;
    }

    this.setState({
      invalidateQueriesQueue: [...this.state.invalidateQueriesQueue, queryKey],
    });
  };

  clearInvalidateQueriesQueue = () => {
    this.setState({
      invalidateQueriesQueue: [],
    });
  };

  toggleSessionModal = () => {
    this.setState({
      showSessionExpired: !this.state.showSessionExpired,
    });
  };

  render() {
    const store = {
      authenticated: this.state.authenticated,
      tokenExpired: this.state.tokenExpired,
      user: this.state.user,
      userId: this.state.userId,
      establishments: this.state.establishments,
      selectedCountry: this.state.selectedCountry,
      currentEstablishment: this.state.currentEstablishment,
      updateUserProfile: this.updateUserProfile,
      logout: this.handleLogout,
      updateEstablishmentOptions: this.updateEstablishmentOptions,
      updateCurrentEstablishment: this.updateCurrentEstablishment,
      updateSelectedCountry: this.updateSelectedCountry,
      activeRoutes: this.state.activeRoutes,
      updateActiveRoute: this.updateActiveRoute,
      sideNavOpen: this.state.sideNavOpen,
      toggleSideNavOpen: this.toggleSideNavOpen,
      resetActiveRoutes: this.resetActiveRoutes,
      themePreference: this.state.themePreference,
      invalidateQueriesQueue: this.state.invalidateQueriesQueue,
      resetInvalidateQueriesQueue: this.clearInvalidateQueriesQueue,
      appendInvalidateQueriesQueue: this.appendInvalidateQueriesQueue,
      recipeFilters: this.state.recipeFilters,
      recipeSelectedPage: this.state.recipeSelectedPage,
      productLibraryPage: this.state.productLibraryPage,
      setRecipeFilters: this.setRecipeFilters,
      setRecipeSelectedPage: this.setRecipeSelectedPage,
      setProductLibraryPage: this.setProductLibraryPage,
      recipeSearchText: this.state.recipeSearchText,
      setRecipeSearchText: this.setRecipeSearchText,
      resetCachedEstablishmentData: this.resetCachedEstablishmentData,
      productLibrarySortAscending: this.state.productLibrarySortAscending,
      toggleProductLibrarySortAscending: this.toggleProductLibrarySortAscending,
      productLibraryPropertyForSort: this.state.productLibraryPropertyForSort,
      setProductLibraryPropertyForSort: this.setProductLibraryPropertyForSort,
      productLibrarySortFilters: this.state.productLibrarySortFilters,
      setProductLibrarySortFilters: this.setProductLibrarySortFilters,
      productLibrarySearchText: this.state.productLibrarySearchText,
      setProductLibrarySearchText: this.setProductLibrarySearchText,
      selectedProductLibraryPanel: this.state.selectedProductLibraryPanel,
      setSelectedProductLibraryPanel: this.setSelectedProductLibraryPanel,
      newProductsToAdd: this.state.newProductsToAdd,
      setNewProductsToAdd: this.setNewProductsToAdd,
    };

    session.check(this.tokenExpired, this.setTokenExpired);

    const factory = SplitSdk({
      core: {
        authorizationKey: configuration.splitKey || "",
        key: this.state.userId,
      },
    });

    return (
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <SplitFactory factory={factory}>
            <ThemeProvider theme={this.state.theme}>
              <GoogleOAuthProvider clientId={"673490331700-tuqqeruom9fjv70ga71v70kj1k43rnvk.apps.googleusercontent.com"}>
              <CssBaseline />
              <Store.Provider value={store}>
                <LocationChangeMonitor>
                  <Routes>
                    <Route
                      element={
                        <Register
                          handleLogInSuccess={this.handleLogInSuccess}
                        />
                      }
                      path="/"
                    />
                    <Route
                      element={
                        <Login handleLogInSuccess={this.handleLogInSuccess} />
                      }
                      path="/login"
                    />
                    <Route element={<ProtectedRoute />}>
                      <Route element={<Profile />} path="/create-profile" />
                      <Route
                        path="establishments"
                        element={<Establishments />}
                      />
                      <Route
                        path="profile-details"
                        element={<ProfileDetails />}
                      />
                      <Route
                        element={
                          <ErrorBoundary>
                            <Layout />
                          </ErrorBoundary>
                        }
                      >
                        <Route
                          element={<Homebase />}
                          path="/homebase/:establishmentid/menus"
                        />
                        <Route
                          element={<MenuDetails />}
                          path="/homebase/:establishmentid/menus/:menuid"
                        />
                        <Route
                          element={<MenuItemDetails />}
                          path="/homebase/:establishmentid/menus/:menuid/:itemid"
                        />
                        <Route
                          element={<StaffManagement />}
                          path="/homebase/:establishmentid/staffmanagement"
                        />
                        <Route
                          element={<AdminMessages />}
                          path="/homebase/:establishmentid/adminmessages"
                        />
                        <Route
                          element={<EstablishmentDetails />}
                          path="/homebase/:establishmentid/details"
                        />
                        <Route
                          element={<SpecClassicCocktails />}
                          path="/homebase/:establishmentid/spec-classics"
                        />
                        <Route
                          element={<ProductLibrary />}
                          path="/spec/:establishmentid/productlibrary"
                        />
                        <Route
                          element={<Vendors />}
                          path="/spec/:establishmentid/vendors"
                        />
                        <Route
                          element={<StockCount />}
                          path="/spec/:establishmentid/stockcount"
                        />
                        <Route
                          element={<StockCountDetails />}
                          path="/spec/:establishmentid/stockcount/:stockcountid"
                        />
                        <Route
                          element={<OrganizationDisplay />}
                          path="/spec/:establishmentid/organization"
                        />
                        <Route
                          element={<AreasDisplay />}
                          path="/spec/:establishmentid/organization/areas"
                        />
                        <Route
                          element={<AreaDetails />}
                          path="/spec/:establishmentid/organization/areas/:areaid"
                        />
                        <Route
                          element={<Families />}
                          path="/spec/:establishmentid/organization/families"
                        />
                        <Route
                          element={<ExpenseGroups />}
                          path="/spec/:establishmentid/organization/expensegroups"
                        />
                        <Route
                          element={<Ordering />}
                          path="/spec/:establishmentid/ordering"
                        />
                        <Route
                          element={<Invoices />}
                          path="/spec/:establishmentid/invoices"
                        />
                        <Route
                          element={<InvoiceDetails />}
                          path="/spec/:establishmentid/invoices/:invoiceid"
                        />
                        <Route
                          element={<NewOrder />}
                          path="/spec/:establishmentid/ordering/new"
                        />
                        <Route
                          element={<OrderReview />}
                          path="/spec/:establishmentid/cart/review"
                        />
                        <Route
                          element={<OrderDetails />}
                          path="/spec/:establishmentid/order/:orderid"
                        />
                        <Route
                          element={<VendorOrders />}
                          path="/spec/:establishmentid/vendororders"
                        />
                        <Route
                          element={<ReviewInvoice />}
                          path="/spec/:establishmentid/invoicedetails"
                        />
                        <Route
                          element={<Reporting />}
                          path="/spec/:establishmentid/reporting"
                        />
                        <Route
                          element={<Menus />}
                          path="/spec/:establishmentid/menus"
                        />
                        <Route
                          element={<MenuDetailsNEW />}
                          path="/spec/:establishmentid/menus/:menuid"
                        />
                        <Route
                          element={<Recipes />}
                          path="/spec/:establishmentid/recipes"
                        />
                        <Route
                          element={<RecipeDetails />}
                          path="/spec/:establishmentid/recipes/:recipeid"
                        />
                        <Route
                          element={<Settings />}
                          path="/spec/:establishmentid/settings"
                        />
                        <Route
                          element={<StockCountReview />}
                          path="/spec/:establishmentid/count-review/:stockcountid/"
                        />
                        <Route
                          element={<Dashboard />}
                          path="/spec/:establishmentid/dashboard"
                        />
                      </Route>
                      <Route
                        element={<MasterSubmissionsControl />}
                        path="/spec-admin"
                      />
                    </Route>
                    <Route
                      element={
                        <Register
                          handleLogInSuccess={this.handleLogInSuccess}
                        />
                      }
                      path="*"
                    />
                  </Routes>
                </LocationChangeMonitor>
                <SessionExpiredModal
                  isOpen={this.state.showSessionExpired}
                  toggle={this.toggleSessionModal}
                />
                <AccountClosedConfirmationModal
                  isOpen={this.state.showAccountClosedModal}
                  toggleModalOpen={this.closeAccountClosedModal}
                />
              </Store.Provider>
              </GoogleOAuthProvider>
            </ThemeProvider>
          </SplitFactory>
        </QueryClientProvider>
      </BrowserRouter>
    );
  }
}

export default App;
