import { Box } from "@mui/material";
import React, { useState } from "react";
import Description from "./Description";
import DateRange from "./DateRange";
import DateGrouping from "./DateGrouping";
import FilterBy from "./FilterBy";
import AdditionalOptions from "./AdditionalOptions";
import {
  invoiceReportTypes,
  reportDateGroupings,
} from "../../../../../clients/inventoryObjects";
import ReportingFooter from "../../ReportingFooter";
import { useMutation } from "react-query";
import inventoryClient from "../../../../../clients/inventoryClient";
import utilFunctions from "../../../../../utilities/utilFunctions";
import { presetDateRangeOptions } from "../../../../../utilities/dateRangeUtils";
import InsufficientReportDataModal from "../../../../../components/Modals/InsufficientReportDataModal";

const dateSelectionFormats = {
  presetOption: 0,
  customRange: 1,
};

const dateGranularities = [
  { key: "Day", value: reportDateGroupings.day },
  { key: "Week", value: reportDateGroupings.week },
  { key: "Month", value: reportDateGroupings.month },
  { key: "Quarter", value: reportDateGroupings.quarter },
  { key: "Year", value: reportDateGroupings.year },
];

const ExpenseGroupReport = ({
  establishmentId,
  establishmentTimezone,
  expenseGroups,
  setAlertSuccess,
  setErrorMessage,
}) => {
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [customStartDateDisplay, setCustomStartDateDisplay] = useState(null);
  const [customEndDateDisplay, setCustomEndDateDisplay] = useState(null);
  const [selectedPresetDateRange, setSelectedPresetDateRange] = useState(
    presetDateRangeOptions[0].value
  );
  const [dateSelectionFormat, setDateSelectionFormat] = useState(
    dateSelectionFormats.presetOption
  );
  const [selectedDateGrouping, setDateGrouping] = useState(
    reportDateGroupings.month
  );
  const [isUsingExpenseGroupFilter, toggleIsUsingExpenseGroupFilter] =
    useState(false);
  const [expenseGroupFilters, setExpenseGroupFilters] = useState([]);
  const [allowUnspecified, toggleAllowUnspecified] = useState(true);
  const [isEmptyResultModalOpen, toggleIsEmptyResultModalOpen] =
    useState(false);
  const [hideVendorsWithNoGroups, toggleHideVendorsWithNoGroups] =
    useState(true);
  const [includeSumAll, toggleIncludeSumAll] = useState(false);
  const [includeExpenseGroupSumRows, toggleIncludeExpenseGroupSumRows] =
    useState(false);

  const generateInvoiceReportMutation = useMutation(
    inventoryClient.generateInvoiceReport,
    {
      onSuccess: (response) => {
        if (response?.status === 204) {
          toggleIsEmptyResultModalOpen(true);
        } else {
          utilFunctions.downloadFile(
            response.data?.report?.invoiceReportMetadata?.s3PresignedUrl
          );
          setAlertSuccess(
            "Report generated successfully, your download should have started."
          );
        }
      },
      onError: () => {
        setErrorMessage("Report generation failed!");
      },
    }
  );

  const handleGenerateReport = () => {
    const payload = {
      establishmentId: establishmentId,
      reportType: invoiceReportTypes.invoiceExpenseGroupsVendorsOverTime,
      invoiceExpenseGroupsVendorsOverTimeFilters: {
        allowUnspecified: allowUnspecified,
        hideVendorsWithNoGroupValues: hideVendorsWithNoGroups,
        dateGroupingSelection: selectedDateGrouping,
        includeAllSumRow: includeSumAll,
        includeExpenseGroupSumRow: includeExpenseGroupSumRows,
        expenseGroupFilters: isUsingExpenseGroupFilter
          ? expenseGroupFilters.map((x) => x.id)
          : [], // api treats an empty array as not wanting filters
      },
    };

    if (dateSelectionFormat == dateSelectionFormats.presetOption) {
      const dateRange = presetDateRangeOptions
        .find((x) => x.value === selectedPresetDateRange)
        .func(establishmentTimezone);
      payload.startDate = dateRange.start;
      payload.endDate = dateRange.end;
    } else {
      payload.startDate = customStartDate;
      payload.endDate = customEndDate;
    }

    generateInvoiceReportMutation.mutate(payload);
  };

  return (
    <Box sx={{ maxWidth: "90%" }}>
      <Box sx={{ marginTop: 8 }}>
        <Description />
      </Box>
      <Box sx={{ marginTop: 8 }}>
        <DateRange
          customStartDate={customStartDate}
          setCustomStartDate={setCustomStartDate}
          customEndDate={customEndDate}
          setCustomEndDate={setCustomEndDate}
          selectedPresetDateRange={selectedPresetDateRange}
          setSelectedPresetDateRange={setSelectedPresetDateRange}
          dateSelectionFormat={dateSelectionFormat}
          setDateSelectionFormat={setDateSelectionFormat}
          dateSelectionFormats={dateSelectionFormats}
          presetDateRangeOptions={presetDateRangeOptions}
          establishmentTimezone={establishmentTimezone}
          customStartDateDisplay={customStartDateDisplay}
          setCustomStartDateDisplay={setCustomStartDateDisplay}
          customEndDateDisplay={customEndDateDisplay}
          setCustomEndDateDisplay={setCustomEndDateDisplay}
        />
      </Box>
      <Box sx={{ marginTop: 8 }}>
        <DateGrouping
          dateGroupings={dateGranularities}
          selectedDateGrouping={selectedDateGrouping}
          setDateGrouping={setDateGrouping}
        />
      </Box>
      <Box sx={{ marginTop: 8 }}>
        <FilterBy
          expenseGroups={expenseGroups}
          isUsingExpenseGroupFilter={isUsingExpenseGroupFilter}
          toggleIsUsingExpenseGroupFilter={toggleIsUsingExpenseGroupFilter}
          setExpenseGroupFilters={setExpenseGroupFilters}
          expenseGroupFilters={expenseGroupFilters}
        />
      </Box>
      <Box sx={{ marginTop: 0 }}>
        <AdditionalOptions
          allowUnspecified={allowUnspecified}
          toggleAllowUnspecified={toggleAllowUnspecified}
          toggleHideVendorsWithNoGroups={toggleHideVendorsWithNoGroups}
          hideVendorsWithNoGroups={hideVendorsWithNoGroups}
          toggleIncludeSumAll={toggleIncludeSumAll}
          includeSumAll={includeSumAll}
          includeExpenseGroupSumRows={includeExpenseGroupSumRows}
          toggleIncludeExpenseGroupSumRows={toggleIncludeExpenseGroupSumRows}
        />
      </Box>
      <ReportingFooter
        buttonText={"Create Report"}
        disableButton={
          isUsingExpenseGroupFilter && expenseGroupFilters?.length <= 0 // if we haven't chosen any expense groups but checked the box
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange && // if we selected custom range but didn't choose dates
              (!customEndDate || !customStartDate)
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange &&
              customEndDate < customStartDate // if the custom end date is before the start date
            ? true
            : false
        }
        submit={handleGenerateReport}
        isLoading={generateInvoiceReportMutation?.isLoading}
      />
      <InsufficientReportDataModal
        isOpen={isEmptyResultModalOpen}
        toggleModalOpen={toggleIsEmptyResultModalOpen}
        loading={false}
      />
    </Box>
  );
};

export default ExpenseGroupReport;
